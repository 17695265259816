<div>

    <div class="banner">
        <div class="container">
            <app-header></app-header>

            <div class="banner-content">
                <h3 class="title">Terms and Conditions</h3>
            </div>
        </div>
    </div>
    <div class="terms-conditions mt-30">
        <div class="container">
            <h2>SPARK FITNESS - TERMS OF USE</h2>
            <p>This web site (the "Site") is owned and operated by Spark Fitness. ("Spark Fitness", "Spark", “Fitness”,
                "we", "us"
                or "our").The use of this Site and the content and information available on this Site (collectively the
                "Services")
                shall be subject to acceptance of and compliance with the terms and conditions set forth in these terms
                of
                use
                (collectively, the "Terms of Use") and elsewhere on this Site. The terms "you," "your", "yours",
                "member"
                "members"
                and "yourself" refer to all visitors/members to this Site. Your agreement to comply with and be bound by
                these Terms
                of Use is deemed to occur upon your first use of the Site. If you do not agree to these Terms of Use,
                you
                should not
                review information or obtain goods, services or products from this Site. The Sample Store has the total
                right to
                edit or delete any content in the Site, including this Agreement, without notifying you.</p>
            <ul>
                <li>
                    <h3>USE OF THIS SITE/APP</h3>

                    <ul>
                        <li>
                            <h3>You agree not to:-</h3>
                            <ul style="list-style: lower-alpha;">
                                <li>
                                    <p>reproduce, copy, distribute, modify, publish, transmit, display, use, reuse,
                                        re-publicize, assign, sublicense, sell, or exploit for any public or commercial
                                        purpose, any
                                        portion of the Site, use of the Site, or access to the Site. </p>
                                </li>
                                <li>
                                    <p>attempt to change, add to, remove, deface, hack or otherwise interfere with
                                        this
                                        Site or
                                        any material or content displayed on this Site; and/or </p>
                                </li>
                                <li>
                                    <p>access or use this Site in any way that could or is intended to damage or
                                        impair
                                        the
                                        Site, or any server or network underlying the Site, or interfere with anyone
                                        else's
                                        use and
                                        enjoyment of the Site.</p>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <h3>USE OF INFORMATION</h3>
                            <p>We have a group privacy policy which sets out how we handle personal data that you may
                                provide or
                                that we may collect, in connection with your access or use of this website. The policy
                                is
                                available
                                on this website. You accept and agree to the terms set out in the policy and consent to
                                the
                                collection, use and disclosure of your personal data in accordance with the policy.</p>
                        </li>
                        <li>
                            <h3>SUBMISSIONS</h3>
                            <p>You may post reviews of products or services, comments, suggestions, or other messages
                                (collectively
                                referred to as “Submissions”) so long as the content of your Submission is not unlawful,
                                threatening, abusive, spiteful, defamatory, invasive of privacy, obscene, profane,
                                sexually
                                explicit, fraudulent or otherwise objectionable or injurious to third parties
                                (including,
                                but not
                                limited to, any content that encourages conduct that would constitute a criminal
                                offense,
                                give rise
                                to civil liability, or otherwise violate applicable law). You may not use a false e-mail
                                address,
                                impersonate any person or entity, or otherwise mislead as to the origin of your
                                Submission.
                                The
                                Sample Store reserves the right (but not the obligation) to monitor, edit and remove any
                                Submission.
                                By posting or sending us any Submission, you grant The Sample Store a non-exclusive,
                                perpetual,
                                irrevocable and fully sublicensable right to use, reproduce, modify, adapt, publish,
                                translate,
                                create derivative works from, distribute, and display such Submission in any media. </p>
                        </li>
                        <li>
                            <h3>PROMOTIONS</h3>
                            <p>From time to time, we may offer and/or co-sponsor contests and games on the Site. Each of
                                these
                                activities shall be governed by specific rules accessible from the Site.</p>
                        </li>
                    </ul>
                </li>
                <li>
                    <h3>DISCLAIMER AND EXCLUSION OF LIABILITY</h3>
                    <h3>II.HOW WE USE AND SHARE INFORMATION</h3>
                    <ul>
                        <li>
                            <h3>Personal Information</h3>
                            <ul style="list-style: number;">
                                <li>
                                    <p> This Site, the Services, the information on this Site and use of all related
                                        facilities
                                        are provided on an "as is, as available" basis without any warranties whether
                                        express or
                                        implied</p>
                                </li>
                                <li>
                                    <p>To the fullest extent permitted by applicable law, we disclaim all
                                        representations
                                        and
                                        warranties relating to this Site and its contents, including in relation to any
                                        inaccuracies
                                        or omissions in this Site, warranties of merchantability, quality, fitness for a
                                        particular
                                        purpose, accuracy, availability, noninfringement or implied warranties from
                                        course
                                        of
                                        dealing or usage of trade. </p>
                                </li>
                                <li>
                                    <p>We do not warrant that this Site will always be accessible, uninterrupted,
                                        timely,
                                        secure,
                                        error free or free from computer virus or other invasive or damaging code or
                                        that
                                        this Site
                                        will not be affected by any Acts of God or other force majeure events, including
                                        inability
                                        to obtain or shortage of necessary materials, equipment facilities, power or
                                        telecommunications, lack of telecommunications equipment or facilities and
                                        failure
                                        of
                                        information technology or telecommunications equipment or facilities. </p>
                                </li>
                                <li>
                                    <p>While we may use reasonable efforts to include accurate and up-to-date
                                        information
                                        on this
                                        Site, we make no warranties or representations as to its accuracy, timeliness or
                                        completeness.WE SHALL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL,
                                        SPECIAL,
                                        CONSEQUENTIAL OR PUNITIVE DAMAGES, HOWSOEVER CAUSED, RESULTING FROM YOUR ACCESS
                                        TO,
                                        USE OF
                                        OR INABILITY TO USE, RELIANCE ON OR DOWNLOADING FROM THE SITE, OR ANY DELAYS,
                                        INACCURACIES
                                        IN THE INFORMATION OR IN ITS TRANSMISSION INCLUDING BUT NOT LIMITED TO DAMAGES
                                        FOR
                                        LOSS OF
                                        BUSINESS OR PROFITS, USE, DATA OR OTHER INTANGIBLE, EVEN IF WE HAVE BEEN ADVISED
                                        OF
                                        THE
                                        POSSIBILITY OF SUCH DAMAGES. </p>
                                </li>
                                <li>
                                    <p>The above exclusions and limitations apply only to the extent permitted by
                                        law.
                                        None of
                                        your statutory rights as a consumer that cannot be excluded or limited are
                                        affected.
                                    </p>
                                </li>
                                <li>
                                    <p>Notwithstanding our efforts to ensure that our Site/system is secure, you
                                        acknowledge that
                                        all electronic data transfers are potentially susceptible to interception by
                                        others.
                                        We
                                        cannot, and do not, warrant that data transfers pursuant to this Site, or
                                        electronic
                                        mail
                                        transmitted to and from us, will not be monitored or read by others. </p>
                                </li>
                                <li>
                                    <p>Although we use reasonable endeavours to ensure that any software made
                                        available
                                        on this
                                        Site is suitable for downloading, installation and use by you, all such software
                                        is
                                        provided
                                        "as is" without any warranty. Specifically, and without limitation, we do not
                                        warrant that
                                        any such software is virus free, without defects, compatible with other software
                                        or
                                        operating systems or suitable for any specific purpose. We accept no liability
                                        for
                                        any loss
                                        or damage caused by the downloading, installation or use of any such software,
                                        and
                                        the
                                        general exclusions and limitations above apply to such downloading, installation
                                        or
                                        use by
                                        you.</p>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li>
                    <h3>HOW WE USE AND SHARE INFORMATION</h3>
                    <ul>
                        <li>
                            <h3>Personal Information:</h3>
                            <p>Except as otherwise stated in this Privacy Policy, we do not sell, trade, rent or
                                otherwise
                                share for
                                marketing purposes your Personal Information with third parties without your consent. We
                                do
                                share
                                Personal Information with vendors who are performing services for the Company, such as
                                the
                                servers
                                for our email communications who are provided access to user’s email address for
                                purposes of
                                sending
                                emails from us. Those vendors use your Personal Information only at our direction and in
                                accordance
                                with our Privacy Policy.In general, the Personal Information you provide to us is used
                                to
                                help us
                                communicate with you. For example, we use Personal Information to contact users in
                                response
                                to
                                questionssolicit feedback from users, provide technical support, and inform users about
                                promotional
                                offers. We may share Personal Information with outside parties if we have a good-faith
                                belief that
                                access, use, preservation or disclosure of the information is reasonably necessary to
                                meet
                                any
                                applicable legal process or enforceable governmental request; to enforce applicable
                                Terms of
                                Service, including investigation of potential violations; address fraud, security or
                                technical
                                concerns; or to protect against harm to the rights, property, or safety of our users or
                                the
                                public
                                as required or permitted by law.</p>
                        </li>
                        <li>
                            <h3>Non-Personal Information</h3>
                            <p>In general, we use Non-Personal Information to help us improve the Service and customize
                                the
                                user
                                experience. We also aggregate Non-Personal Information in order to track trends and
                                analyze
                                use
                                patterns on the Site. This Privacy Policy does not limit in any way our use or
                                disclosure of
                                Non-Personal Information and we reserve the right to use and disclose such Non-Personal
                                Information
                                to our partners, advertisers and other third parties at our discretion. In the event we
                                undergo a
                                business transaction such as a merger, acquisition by another company, or sale of all or
                                a
                                portion
                                of our assets, your Personal Information may be among the assets transferred. You
                                acknowledge and
                                consent that such transfers may occur and are permitted by this Privacy Policy, and that
                                any
                                acquirer of our assets may continue to process your Personal Information as set forth in
                                this
                                Privacy Policy. If our information practices change at any time in the future, we will
                                post
                                the
                                policy changes to the Site so that you may opt out of the new information practices. We
                                suggest that
                                you check the Site periodically if you are concerned about how your information is used.
                            </p>
                        </li>

                    </ul>
                </li>
                <li>
                    <h3>LINKS TO THIS SITE</h3>
                    <p>You may not create a link to any page of this Site without our prior written consent. If you do
                        create a link
                        to a page of this Site, you do so at your own risk and these Terms of Use will apply to your use
                        of
                        this
                        Site by linking to it.</p>
                </li>
                <li>
                    <h3>THIRD PARTY ADVERTISEMENTS AND LINKS TO THIRD PARTY SITES</h3>
                    <p>The Sample Store may display advertisements from third parties, such as banner advertisements and
                        pop-up
                        texts, and links to third party sites, on the Site. We are not responsible for the content of
                        such
                        advertisements or links, any products, services or other materials relating to such
                        advertisements,
                        any
                        linked site, or any link contained in a linked site. The display of any such advertisement or
                        link
                        does not
                        imply endorsement by The Sample Store of the advertisement or linked site or any content
                        therein. IN
                        NO
                        EVENT WILL THE SAMPLE STORE BE LIABLE, DIRECTLY OR INDIRECTLY, TO ANYONE FOR ANY DAMAGE OR LOSS
                        ARISING FROM
                        OR RELATING TO ANY USE, CONTINUED USE OR RELIANCE ON ANY THIRD PARTY ADVERTISEMENT DISPLAYED ON
                        THE
                        SITE,
                        ANY PRODUCTS, SERVICES OR OTHER MATERIALS RELATING TO ANY SUCH ADVERTISEMENT, ANY LINKED THIRD
                        PARTY
                        SITE OR
                        ANY LINK CONTAINED IN A LINKED SITE.</p>
                </li>
                <li>
                    <h3>INTELLECTUAL PROPERTY RIGHTS</h3>
                    <p>All editorial content, information, photographs, illustrations, artwork and other graphic
                        materials,
                        and
                        names, logos and trade marks on this Site are protected by copyright laws and/or other laws
                        and/or
                        international treaties, and belong to us and/or our suppliers, as the case may be. These works,
                        logos,
                        graphics, sounds or images may not be copied, reproduced, retransmitted, distributed,
                        disseminated,
                        sold,
                        published, broadcasted or circulated whether in whole or in part, unless expressly permitted by
                        us
                        and/or
                        our suppliers, as the case may be. Nothing contained on the Site should be construed as granting
                        by
                        implication, estoppel, or otherwise, any license or right to use any trademark displayed on the
                        Site
                        without
                        our written permission. Misuse of any trademarks or any other content displayed on the Site is
                        prohibited.
                        We will not hesitate to take legal action against any unauthorised usage of its trade marks,
                        name or
                        symbols
                        to preserve and protect its rights in the matter. All rights not expressly granted herein are
                        reserved.
                        Other product and company names mentioned herein may also be the trade marks of their respective
                        owners.</p>
                </li>
                <li>
                    <h3>CHANGES TO TERMS OF USE</h3>
                    <p>These Terms of Use may be modified from time to time. Any modifications to these Terms of Use
                        will be
                        effective upon posting. You should therefore read these Terms of Use before you place any order.
                        Your
                        continued use of the Site after any modifications to the Terms of Use indicates your acceptance
                        of
                        the
                        modified Terms of Use</p>
                </li>
                <li>
                    <h3>GOVERNING LAW AND JURISDICTION.</h3>
                    <p>This Site may be accessed from all countries around the world where the local technology permits.
                        As
                        each
                        country has differing laws, by accessing this Site both we and you agree that the laws of the
                        Republic of
                        Singapore, without regard to the conflict of laws principles thereof, will apply to all matters
                        relating to
                        these Terms of Use. You accept and agree to submit to the exclusive jurisdiction of the courts
                        of
                        the India
                        in respect of any dispute or difference arising out of and/or in connection with these Terms of
                        Use
                    </p>
                </li>
            </ul>
        </div>

    </div>
    <!--Footer-->
    <app-footer></app-footer>

</div>