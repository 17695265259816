<!--Footer-->
<div class="footer">
    <div class="container">
        <div class="row" fxLayout="row wrap" class="pt-60">
            <div fxFlex="100" fxFlex.gt-xs="35" fxFlex.sm="35" fxFlex.md="35" fxFlex.xs="100">
                <div class="contact-sec pr-15">
                    <img src="assets/images/footer-logo.png" />
                    <ul>
                        <li class="d-flex">
                            <mat-icon>place</mat-icon><span>2nd East Main Road, 2nd Floor, Reliance Fresh Upstairs, Anna Nagar,
                                Madurai.</span>
                        </li>
                        <li>
                            <mat-icon>phone_in_talk</mat-icon><span> +91 - 8438088438</span>
                        </li>
                    </ul>
                    <div class=" mt-60">
                        <h3 class="heading">Opening Times</h3>
                        <h5>Mon - Sat : 5:30am - 11:00pm</h5>
                        <h5>Sun : 6:00am - 12:00pm</h5>
                    </div>
                </div>
            </div>
            <div fxFlex="100" fxFlex.gt-xs="20" fxFlex.sm="20" fxFlex.md="20" fxFlex.xs="100">
                <h3 class="heading">Useful Links</h3>
                <ul>
                    <li><a href="#" [routerLink]="'/privacy-policy'">Privacy Policy</a></li>
                    <li><a href="#" [routerLink]="'/terms-conditions'">Terms and Conditions</a></li>
                    <li><a (click)="openDialog()" style="cursor: pointer;">Contact</a></li>
                </ul>
            </div>
            <div fxFlex="100" fxFlex.gt-xs="20" fxFlex.sm="20" fxFlex.md="20" fxFlex.xs="100">
                <h3 class="heading">Quick Links</h3>
                <ul>
                  
                    <li><a (click)="goToAboutus()" >About Us</a></li>
                    <li><a (click)="goToClasses()">Classes</a></li>
                    <li><a (click)="goToGalary()">Gallery</a></li>
                  
                </ul>
            </div>
            <div fxFlex="100" fxFlex.gt-xs="25" fxFlex.sm="25" fxFlex.md="25" fxFlex.xs="100" class="text-right">
                <h2 class="fw-400">Download our App</h2>
                <a href="https://play.google.com/store/apps/details?id=com.spark.customer.android" target="_blank">
                    <img src="assets/images/google-play.png" width="50%" />
                </a><br>
                <a href="https://apps.apple.com/in/app/spark-fitness/id1577501575" target="_blank">
                    <img src="assets/images/appstore.png" width="50%" />
                </a>
                <ul class="d-flex-center-end mt-260">
                    <li class="pr-25"><a href="https://www.facebook.com/sparkfitness.fit/"><img src="assets/images/social-media-icons/facebook.png" width="100%" /></a></li>
                    <li class="pr-25"><a href="https://www.instagram.com/spark_fitness.fit/?igshid=YmMyMTA2M2Y="><img src="assets/images/social-media-icons/insta.png" width="100%" /></a></li>
                    <li class="pr-25"><img src="assets/images/social-media-icons/twitter.png" width="100%" /></li>
                    <li class="pr-25"><img src="assets/images/social-media-icons/youtube.png" width="100%" /></li>
                </ul>
            </div>
        </div>
        <div class="text-center mt-60">
            <span class="copyright">Copyright © 2021 Spark Fitness, All rights reserved</span>
            <h5 class="powered-by">Powered by The Petal
                    Automation</h5>
        </div>
    </div>
</div>