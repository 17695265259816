import { ViewportScroller } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EnquiryComponent } from 'src/app/home/enquiry/enquiry.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  show: boolean = false;
  path:string;
  constructor(public dialog: MatDialog,private scroller: ViewportScroller) { }

  ngOnInit(): void {
    this.path = window.location.pathname;
  }
  openDialog() {
    this.dialog.open(EnquiryComponent,{
      width: '420px',
      disableClose: true,
      hasBackdrop:true,
      panelClass: 'enquiry-popup'
    });
  }
  goToGalary(){
    if(this.path == '/home' || this.path.includes('/home')){
      this.scroller.scrollToAnchor("gallery");
    }else{
      window.location.href = '/home#gallery';
    }
  }
  goToClasses(){
    if(this.path == '/home' || this.path.includes('/home')){
      this.scroller.scrollToAnchor("classes");
    }else{
      window.location.href = '/home#classes';
    }
  }
  goToAboutus(){
    if(this.path == '/home' || this.path.includes('/home')){
      this.scroller.scrollToAnchor('aboutus');
    }else{
      window.location.href = '/home#aboutus';
    }
  }
  goToTestimonials(){
    if(this.path == '/home' || this.path.includes('/home')){
      this.scroller.scrollToAnchor('testimonials');
    }else{
      window.location.href = '/home#testimonials';
    }
  }
}
