import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-enquiry',
  templateUrl: './enquiry.component.html',
  styleUrls: ['./enquiry.component.scss']
})
export class EnquiryComponent implements OnInit {
  enquiryForm: FormGroup;
  constructor( private http: HttpClient, public dialogRef: MatDialogRef<EnquiryComponent>,) { }

  ngOnInit(): void {
    this.form();
  }

  form(){
    this.enquiryForm = new FormGroup({
      name: new FormControl('', [Validators.required, Validators.pattern(/^\S$|^\S[\s\S]*\S$/)]),
      mobile: new FormControl('', [Validators.required, Validators.pattern(/^[0-9]{10}$/)]),
      email: new FormControl('', [Validators.required,  Validators.email, Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/)]),
      subject: new FormControl('', [Validators.required, Validators.pattern(/^\S$|^\S[\s\S]*\S$/)]),
      message: new FormControl('', [Validators.required, Validators.pattern(/^\S$|^\S[\s\S]*\S$/)])
    });
  }

  get control() {
    return this.enquiryForm.controls;
  }

  save() {
    if (this.enquiryForm.valid) {
      this.Loaderopen();
      let formData = new FormData();
      formData.append("name", this.enquiryForm.value.name);
      formData.append("mobile", this.enquiryForm.value.mobile);
      formData.append("email", this.enquiryForm.value.email);
      formData.append("subject", this.enquiryForm.value.subject);
      formData.append("message", this.enquiryForm.value.message);
      this.post(`joinNowEnquiry`, formData).subscribe((result: any) => {
        if (result.keyword == 'success') {
          Swal.showLoading();
          this.close();
          Swal.fire({
            icon: 'success',
            title: 'Enquiry send successfully',
            width: '22em',
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: false,
            timer: 2000
          })
        } else {
          Swal.showLoading();
          Swal.fire({
            icon: 'error',
            title: 'Oops...!',
            text: 'Something went wrong!',
            width: '22em',
            customClass: {
              title: 'swaltitle-error'
            },
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: false,
           timer: 2000
          })
        }
      });
    }else{
      this.enquiryForm.markAllAsTouched();
    }

  }
 
  Loaderopen(){
    Swal.fire({
      title: 'Please wait...',
      width: '13em',
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading()
      }
    });
  }

  close(): void {
    this.dialogRef.close();
  }

  post(path: string, body: Object = {}): Observable<any> {
    return this.http.post(
      `${environment.apiURL}${path}`,
      body
    );
  }
}
