<div class="header">
    <div class="row" fxLayout="row " fxLayoutAlign="space-between center">
        <div>
            <a href="#" [routerLink]="'/home'"> <img src="assets/images/logo.png" /></a>
        </div>
        <div>
            <span class="toggle-btn" (click)="show = !show">
                <mat-icon>menu</mat-icon>
            </span>
            <ul class="web">
                <li><a href="#" [routerLink]="'/home'">Home</a></li>
                <li><a (click)="goToAboutus()">About Us</a></li>
                <li><a (click)="goToClasses()">Classes</a></li>
                <li><a (click)="goToGalary()">Gallery</a></li>
                <li><a (click)="goToTestimonials()" >Testimonials</a></li>
             
                
                <li><button (click)="openDialog()" mat-flat-button>Join Now</button></li>
            </ul>

        </div>
    </div>
    <ul class="mobile" *ngIf="show">
        <li><a href="#" [routerLink]="'/home'">Home</a></li>
        <li><a (click)="goToAboutus()">About Us</a></li>
        <li><a (click)="goToClasses()">Classes</a></li>
        <li><a (click)="goToGalary()">Gallery</a></li>
        <li><a (click)="goToTestimonials()" >Testimonials</a></li>
     
        
        <li><button (click)="openDialog()" mat-flat-button>Join Now</button></li>
    </ul>
</div>