<div class="page">
    <!--Banner-->
    <div class="banner">
        <div class="container">
            <app-header></app-header>

            <div class="banner-content">
                <h5><span class="pr-10">
                        <hr>
                    </span>
                    with spark fitness
                </h5>
                <h1>Build perfect body <br>shape for good <br>& Healthy life.<span>
                        <img src="assets/images/playbutton.png" class="play-btn" />
                    </span></h1>
                    <a href="https://wa.me/+918438088438" class="whatsapp_float" target="_blank" rel="noopener noreferrer">
                        <button (click)="openDialog()" mat-flat-button>GET FREE CONSULTATION</button>
                    </a>
                <ul class="d-flex-acenter">
                    <li class="pr-25"><a href="https://www.facebook.com/sparkfitness.fit/"><img src="assets/images/social-media-icons/facebook.png" width="100%" /></a></li>
                    <li class="pr-25"><a href="https://www.instagram.com/spark_fitness.fit/?igshid=YmMyMTA2M2Y="><img src="assets/images/social-media-icons/insta.png" width="100%" /></a></li>
                    <li class="pr-25"><img src="assets/images/social-media-icons/twitter.png" width="100%" /></li>
                    <li class="pr-25"><img src="assets/images/social-media-icons/youtube.png" width="100%" /></li>
                </ul><br><br><br><br>
                <div class="text-center">
                    <img src="assets/images/scroll-down.png" />
                </div>

            </div>

        </div>
    </div>
    <!-- Why us -->
    <div class=" why-us">
        <div class="container">
            <h2 class="text-center"><strong>Why us?</strong></h2>
            <div class="row" fxLayout="row wrap" fxLayoutAlign="space-between">
                <div fxFlex="100" fxFlex.gt-xs="25" fxFlex.sm="50" fxFlex.md="25" fxFlex.xs="100" class="pr-25">
                    <div class="text-center">
                        <div data-wow-iteration="2" class="icon-img wow swing center">
                            <img src="assets/images/why-us/quality-equipment.png" />
                        </div>
                        <h4>Quality Equipment</h4>
                        <p>The treadmills, decline bench, dumbbells and
                            wide
                            range of the equipment are available at one point.</p>
                    </div>
                </div>
                <div fxFlex="100" fxFlex.gt-xs="25" fxFlex.sm="50" fxFlex.md="25" fxFlex.xs="100" class="pr-25">
                    <div class="text-center">
                        <div data-wow-iteration="2" class="icon-img wow swing center">
                            <img src="assets/images/why-us/quality-equipment.png" />
                        </div>
                        <h4>Professional training plan</h4>
                        <p>Customised and nutrients trainers are available to guide and track your fitness.</p>
                    </div>
                </div>
                <div fxFlex="100" fxFlex.gt-xs="25" fxFlex.sm="50" fxFlex.md="25" fxFlex.xs="100" class="pr-25">
                    <div class="text-center">
                        <div data-wow-iteration="2" class="icon-img wow swing center">
                            <img src="assets/images/why-us/nutrition-plan.png" />
                        </div>
                        <h4>Automated Reminders</h4>
                        <p>Members can get the payment renewal date, birthday wishes, attendance, feedbacks,etc.,</p>
                    </div>
                </div>
                <div fxFlex="100" fxFlex.gt-xs="25" fxFlex.sm="50" fxFlex.md="25" fxFlex.xs="100">
                    <div class="text-center">
                        <div data-wow-iteration="2" class="icon-img wow swing center">
                            <img src="assets/images/why-us/needs.png" />
                        </div>
                        <h4>Health Club Management</h4>
                        <p>Manage your appointment and schedule the diet/consultation slots. </p>
                    </div>
                </div>




            </div>
        </div>
    </div>

    <!-- About us -->
    <div class="about-us" id="aboutus">
        <div fxLayout="row wrap">
            <div fxFlex="100" fxFlex.gt-xs="50" fxFlex.sm="50" fxFlex.md="50" fxFlex.xs="100" class="wow bounceInLeft">
                <img src="assets/images/about-us.png" width="100%" height="100%" />
            </div>
            <div fxFlex="100" fxFlex.gt-xs="50" fxFlex.sm="50" fxFlex.md="50" fxFlex.xs="100" class="about-content">
                <div class="wow bounceInRight ">
                    <h5>About Us</h5>
                    <h2>Welcome To</h2>
                    <h3>Spark Fitness</h3>
                    <p>When we opened our gym in Madurai around 2016, Spark is a mind of the art gym that focuses on
                        only fitness. We will show them how to adopt fitness diet that will completely improve the
                        quality of their lives. We are providing the complete step of personal training program for
                        cinema artist who can truly train and motivate you. Our focus will always be to improve our
                        service standards. And to lead by example. People weight loss and wellness is the key in spark
                        which is provide the all in one. More than 2000+pepole have used our facility at till date and
                        150+ customers join the gym on monthly basics.</p>
                    <!-- <button mat-raised-button color="warn">Know More<mat-icon>chevron_right</mat-icon></button> -->
                </div>

            </div>
        </div>
    </div>

    <!-- Classes -->
    <div class="classes" id="classes">
        <h3>Classes Designed</h3>
        <h1>For you</h1>
        <div fxLayout="row wrap">
            <div fxFlex="100" fxFlex.gt-xs="50" fxFlex.sm="50" fxFlex.md="50" fxFlex.xs="100" class="pr-15">

                <div class="class-img-sec text-center wow bounceInLeft"
                    style="background-image: url('/assets/images/classes-image/group-training.png');">
                    <img src="assets/images/classes-icon/group.png" width="30%" />
                    <h3>Group Training</h3>
                    <p>Improve our body strength.<br>Join our group trainings and<br> get in shape fast.</p>
                    <h4>Read More <span><i class="fa fa-arrow-right" aria-hidden="true"></i></span></h4>
                </div>
            </div>
            <div fxFlex="100" fxFlex.gt-xs="50" fxFlex.sm="50" fxFlex.md="50" fxFlex.xs="100">
                <div fxLayout="row wrap">
                    <div fxFlex="100" fxFlex.gt-xs="50" fxFlex.sm="50" fxFlex.md="50" fxFlex.xs="100" class="pr-15">
                        <div class="class-img-sec1 text-center wow bounceInDown"
                            style="background-image: url('/assets/images/classes-image/personal-training.png');">
                            <img src="assets/images/classes-icon/personal-training.png" width="30%" />
                            <h3>Personal Training</h3>
                        </div>
                    </div>
                    <div fxFlex="100" fxFlex.gt-xs="50" fxFlex.sm="50" fxFlex.md="50" fxFlex.xs="100">
                        <div class="class-img-sec1 text-center wow bounceInRight"
                            style="background-image: url('/assets/images/classes-image/cardio.png');">
                            <img src="assets/images/classes-icon/cardio.png" width="30%" />
                            <h3>Cardio Program</h3>
                        </div>
                    </div>
                    <div fxFlex="100" fxFlex.gt-xs="50" fxFlex.sm="50" fxFlex.md="50" fxFlex.xs="100"
                        class="pt-15 pr-15">
                        <div class="class-img-sec1 text-center wow bounceInUp"
                            style="background-image: url('/assets/images/classes-image/outdoor.png');">
                            <div style="position: relative;bottom: 20px;">
                                <img src="assets/images/classes-icon/outdoot.png" width="22%" />
                                <h3>Outdoor Classes</h3>
                            </div>
                        </div>
                    </div>
                    <div fxFlex="100" fxFlex.gt-xs="50" fxFlex.sm="50" fxFlex.md="50" fxFlex.xs="100" class="pt-15">
                        <div class="class-img-sec1 text-center wow bounceInRight"
                            style="background-image: url('/assets/images/classes-image/digital.png');">
                            <img src="assets/images/classes-icon/digital.png" width="30%" />
                            <h3>Digital Coaching</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!--Trainers-->
    <!-- <div class="trainers">
        <div class="container">
            <h3>Trainers</h3>
            <h1>Our Coaches</h1>
            <div class="row" fxLayout="row wrap" class="mt-30">
                <div fxFlex="100" fxFlex.gt-xs="33" fxFlex.sm="33" fxFlex.md="33" fxFlex.xs="100" class="pr-70">
                    <div class="card wow bounceInLeft">
                        <img class="trainer-img" src="assets/images/trainers/01.png" width="100%" height="450px" />
                        <div class="card-footer d-flex-center px-15">
                            <h3>Mark Brook</h3>
                            <ul>
                                <li><img src="assets/images/social-media-icons/facebook.png" width="100%" />
                                </li>
                                <li><img src="assets/images/social-media-icons/insta.png" width="100%" /></li>
                                <li><img src="assets/images/social-media-icons/twitter.png" width="100%" /></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div fxFlex="100" fxFlex.gt-xs="33" fxFlex.sm="33" fxFlex.md="33" fxFlex.xs="100" class="pr-70">
                    <div class="card">
                        <img class="trainer-img" src="assets/images/trainers/02.png" width="100%" height="450px" />
                        <div class="card-footer d-flex-center px-15">
                            <h3>Mark Brook</h3>
                            <ul>
                                <li><img src="assets/images/social-media-icons/facebook.png" width="100%" />
                                </li>
                                <li><img src="assets/images/social-media-icons/insta.png" width="100%" /></li>
                                <li><img src="assets/images/social-media-icons/twitter.png" width="100%" /></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div fxFlex="100" fxFlex.gt-xs="33" fxFlex.sm="33" fxFlex.md="33" fxFlex.xs="100">
                    <div class="card wow bounceInRight">
                        <img class="trainer-img" src="assets/images/trainers/01.png" width="100%" height="450px" />
                        <div class="card-footer d-flex-center px-15">
                            <h3>Mark Brook</h3>
                            <ul>
                                <li><img src="assets/images/social-media-icons/facebook.png" width="100%" />
                                </li>
                                <li><img src="assets/images/social-media-icons/insta.png" width="100%" /></li>
                                <li><img src="assets/images/social-media-icons/twitter.png" width="100%" /></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div> -->

    <!--Gallery-->
    <div class="gallery" id="gallery">
        <div class="container">
            <h3>From our Gallery</h3>
            <div class="row" fxLayout="row wrap" class="mt-30">
                <div fxFlex="100" fxFlex.gt-xs="33" fxFlex.sm="33" fxFlex.md="33" fxFlex.xs="100"
                    class="wow bounceInLeft pr-15">
                    <img src="assets/images/gallery/PHOTO- 1.jpeg" width="100%" />
                    <img src="assets/images/gallery/PHOTO- 2.jpeg" width="100%"  />
                </div>
                <div fxFlex="100" fxFlex.gt-xs="33" fxFlex.sm="33" fxFlex.md="33" fxFlex.xs="100" class="pr-15">
                    <img src="assets/images/gallery/PHOTO- 4.jpeg" width="100%" />
                    <img src="assets/images/gallery/PHOTO- 7.jpeg" width="100%" />
                </div>
                <div fxFlex="100" fxFlex.gt-xs="33" fxFlex.sm="33" fxFlex.md="33" fxFlex.xs="100"
                    class="wow bounceInRight">
                    <img src="assets/images/gallery/PHOTO- 8.jpeg" width="100%"  />
                    <img src="assets/images/gallery/PHOTO- 11.jpeg" width="100%"  />
                </div>
            </div>
            <!-- <div class="text-center">
                <button mat-raised-button color="warn">View More<mat-icon>chevron_right</mat-icon></button>
            </div> -->
        </div>
    </div>

    <!--Testimonials-->
    <!-- <div class="testimonials" id="testimonials">
        <div class="container">
            <h3>Testimonials</h3>
            <div class="row" fxLayout="row wrap" class="px-50 pt-60 d-flex-center wow bounceInLeft">
                <div fxFlex="100" fxFlex.gt-xs="20" fxFlex.sm="30" fxFlex.md="20" fxFlex.xs="100" class="text-center">
                    <img src="assets/images/testimonials/avatar.png" />
                </div>
                <div fxFlex="100" fxFlex.gt-xs="80" fxFlex.sm="70" fxFlex.md="80" fxFlex.xs="100" class="message">
                    <p>"My whole life I've wanted to be in great shape. I worked with jorge for five months and
                        got
                        into
                        the best shape I've ever been in. Thank you!"</p>
                    <h3>Dawson Leary</h3>
                </div>
            </div>
        </div>
    </div> -->

    <!--Membership-->
    <!-- <div class="membership">
        <div class="container">
            <h3>Membership</h3>
            <div class="row" fxLayout="row wrap" class="">
                <div fxFlex="100" fxFlex.gt-xs="33" fxFlex.sm="33" fxFlex.md="33" fxFlex.xs="100"
                    class="text-center pr-45">
                    <div class="br-30">
                        <div class="price-details mt-80">
                            <h2>Normal</h2>
                            <h1>₹ 2500</h1>
                            <h3>Monthly</h3>
                            <p>Free riding <br>unlimited equipments<br> personal trainer<br> weight losing
                                classes<br>
                                Month
                                to month <br>No time restriction</p>
                            <button mat-flat-button>Get Started</button>
                        </div>
                    </div>
                </div>
                <div fxFlex="100" fxFlex.gt-xs="33" fxFlex.sm="33" fxFlex.md="33" fxFlex.xs="100"
                    class="text-center pr-45">
                    <div class="price-details">
                        <h2>Professional</h2>
                        <h1>₹ 3000</h1>
                        <h3>Monthly</h3>
                        <p>Free riding <br>unlimited equipments<br> personal trainer<br> weight losing
                            classes<br>
                            Month
                            to month <br>No time restriction</p>
                        <button mat-flat-button>Get Started</button>
                    </div>
                </div>
                <div fxFlex="100" fxFlex.gt-xs="33" fxFlex.sm="33" fxFlex.md="33" fxFlex.xs="100" class="text-center">
                    <div class="price-details mt-80">
                        <h2>Advanced</h2>
                        <h1>₹ 5000</h1>
                        <h3>Monthly</h3>
                        <p>Free riding <br>unlimited equipments<br> personal trainer<br> weight losing
                            classes<br>
                            Month
                            to month <br>No time restriction</p>
                        <button mat-flat-button>Get Started</button>
                    </div>
                </div>
            </div>
        </div>
    </div> -->

    <!--Footer-->
    <app-footer></app-footer>