    <div class="card">
    <div class="head">
        <h1 class="heading" mat-dialog-title>Enquiry Form</h1>
        <a class="close" mat-dialog-close>
            <mat-icon class="close-icon">clear</mat-icon>
        </a>

    </div>


    <mat-dialog-content>
        <div class="form">
            <form [formGroup]="enquiryForm">
            <mat-form-field appearance="outline" class="full-width">
                <mat-label> Name <span class="required_symbol">*</span></mat-label>
                <input matInput id="#billno" formControlName="name" cdkFocusInitial>
                <span *ngIf="control.name.errors?.required && control.name?.touched" matSuffix
                [matTooltip]="'Please provide the name'"
                [matTooltipDisabled]="!control.name?.invalid" matTooltipClass="tooltip-red">
                <mat-icon color="warn" style="cursor: pointer;">info
                </mat-icon>
            </span>        
            <span *ngIf="control.name.errors?.pattern && control.name?.touched" matSuffix
                [matTooltip]="'Please provide the valid name'"
                [matTooltipDisabled]="!control.name?.invalid" matTooltipClass="tooltip-red">
                <mat-icon color="warn" style="cursor: pointer;">info
                </mat-icon>
            </span>
            </mat-form-field>

            <mat-form-field appearance="outline" class="full-width">
                <mat-label> Mobile <span class="required_symbol">*</span></mat-label>
                <input matInput id="#billno" formControlName="mobile" cdkFocusInitial>
                <span *ngIf="control.mobile.errors?.required && control.mobile?.touched" matSuffix
                [matTooltip]="'Please provide the mobile no'"
                [matTooltipDisabled]="!control.mobile?.invalid" matTooltipClass="tooltip-red">
                <mat-icon color="warn" style="cursor: pointer;">info
                </mat-icon>
            </span>        
            <span *ngIf="control.mobile.errors?.pattern && control.mobile?.touched" matSuffix
                [matTooltip]="'Please provide the valid mobile no'"
                [matTooltipDisabled]="!control.mobile?.invalid" matTooltipClass="tooltip-red">
                <mat-icon color="warn" style="cursor: pointer;">info
                </mat-icon>
            </span>
            </mat-form-field>

            <mat-form-field appearance="outline" class="full-width">
                <mat-label> Email <span class="required_symbol">*</span></mat-label>
                <input matInput id="#billno" formControlName="email" cdkFocusInitial>
                <span *ngIf="control.email.errors?.required && control.email?.touched" matSuffix
                [matTooltip]="'Please provide the email'"
                [matTooltipDisabled]="!control.email?.invalid" matTooltipClass="tooltip-red">
                <mat-icon color="warn" style="cursor: pointer;">info
                </mat-icon>
            </span>        
            <span *ngIf="control.email.errors?.pattern && control.email?.touched" matSuffix
                [matTooltip]="'Please provide the valid email'"
                [matTooltipDisabled]="!control.email?.invalid" matTooltipClass="tooltip-red">
                <mat-icon color="warn" style="cursor: pointer;">info
                </mat-icon>
            </span>
            </mat-form-field>

            <mat-form-field appearance="outline" class="full-width">
                <mat-label> Subject <span class="required_symbol">*</span></mat-label>
                <input matInput id="#billno" formControlName="subject" cdkFocusInitial>
                <span *ngIf="control.subject.errors?.required && control.subject?.touched" matSuffix
                [matTooltip]="'Please provide the subject'"
                [matTooltipDisabled]="!control.subject?.invalid" matTooltipClass="tooltip-red">
                <mat-icon color="warn" style="cursor: pointer;">info
                </mat-icon>
            </span>        
            <span *ngIf="control.subject.errors?.pattern && control.subject?.touched" matSuffix
                [matTooltip]="'Please provide the valid subject'"
                [matTooltipDisabled]="!control.subject?.invalid" matTooltipClass="tooltip-red">
                <mat-icon color="warn" style="cursor: pointer;">info
                </mat-icon>
            </span>
            </mat-form-field>

            <mat-form-field appearance="outline" class="full-width">
                <mat-label>Message/Comments<span class="required_symbol">*</span></mat-label>
                <textarea matInput formControlName="message"></textarea>
                <span *ngIf="control.message.errors?.required && control.message?.touched" matSuffix
                [matTooltip]="'Please provide the message/comments'"
                [matTooltipDisabled]="!control.message?.invalid" matTooltipClass="tooltip-red">
                <mat-icon color="warn" style="cursor: pointer;">info
                </mat-icon>
            </span>        
            <span *ngIf="control.message.errors?.pattern && control.message?.touched" matSuffix
                [matTooltip]="'Please provide the valid message/comments'"
                [matTooltipDisabled]="!control.message?.invalid" matTooltipClass="tooltip-red">
                <mat-icon color="warn" style="cursor: pointer;">info
                </mat-icon>
            </span>
            </mat-form-field>
            </form>
        </div>
        <div class="submit mb-12">
            <button mat-raised-button class="rounded-primary-btn arrow-btn" (click)="save()">
                Submit
            </button>
        </div>
    </mat-dialog-content>
</div>