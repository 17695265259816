import { Component, OnInit } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import { EnquiryComponent } from './enquiry/enquiry.component';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  show: boolean = false;
  constructor(public dialog: MatDialog) { }


  ngOnInit(): void {
  }
// menushow(){
//   this.show = true;
//   this.show != this.show;
//   console.log(this.show);
// }

openDialog() {
  this.dialog.open(EnquiryComponent,{
    width: '420px',
    disableClose: true,
    hasBackdrop:true,
    panelClass: 'enquiry-popup'
  });
}

}
